"use client";
import {
  FormGroup,
  TextArea,
  Form,
  FormItem,
  Input,
  Link,
  InputType,
  Text,
  List,
  Button,
  StandardListItem,
  Modals,
  Icon,
  Toast,
} from "@ui5/webcomponents-react";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import React, { useState, useEffect, useRef, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upsertwebdetails, getwebdetails } from "../services/carsSlice";
const StandardField = ({
  editMode,
  value,
  inputType = InputType.None,
  onInput,
  ...rest
}) => {
  if (editMode) {
    return (
      <Input
        value={value}
        style={{ width: "100%" }}
        type={inputType}
        onInput={onInput}
        {...rest}
      />
    );
  }
  if (inputType === InputType.URL || inputType === InputType.Email) {
    return (
      <Link
        href={inputType === InputType.Email ? `mailto:${value}` : value}
        target="_blank"
        {...rest}
      >
        {value}
      </Link>
    );
  }
  return <Text {...rest}>{value}</Text>;
};

const reducer = (state, { field, value }) => {
  return { ...state, [field]: value };
};

const Contact = (props) => {
  const showToast = Modals.useShowToast();
  const [cardata, setcardata] = useState(null);
  const [year, setyear] = useState(cardata?.year || []);
  const [wheeldrive, setwheeldrive] = useState(cardata?.wheeldrive || []);
  const [make, setmake] = useState(cardata?.make || []);
  const [trim, settrim] = useState(cardata?.trim || []);
  const [cartype, setcartype] = useState(cardata?.cartype || []);
  const handlemakeRemove = (id) => {
    let copy = [...make];
    copy.splice(id, 1);
    setmake(copy);
  };
  const handleyearRemove = (id) => {
    let copy = [...year];
    copy.splice(id, 1);
    setyear(copy);
  };
  const handlewheeldriveRemove = (id) => {
    let copy = [...wheeldrive];
    copy.splice(id, 1);
    setwheeldrive(copy);
  };

  const handlecartypeRemove = (id) => {
    let copy = [...cartype];
    copy.splice(id, 1);
    setcartype(copy);
  };

  
  const handletrimRemove = (id) => {
    let copy = [...trim];
    copy.splice(id, 1);
    settrim(copy);
  };
  
  const [editMode, toggleEditMode] = useReducer(
    (prev) => !prev,
    false,
    undefined
  );
  useEffect(() => {
    getCarsList();
  }, []);

  const getCarsList = () => {
    apidispatch(getwebdetails())
      .then((values) => {
        let result = values?.payload?.data?.result;
        setcardata(result);
        setyear(result.year);
        setmake(result.make);
        setwheeldrive(result.wheeldrive);
        setcartype(result.cartype);
        settrim(result.trim);
        Object.keys(result).forEach(function (key, index) {
          dispatch({ field: key, value: result[key] });
        });
      })
      .catch((error) => {});
  };
  const [formState, dispatch] = useReducer(
    reducer,
    {
      name: cardata?.name || "",
      street: cardata?.street || "",
      zip: cardata?.zip || "",
      city: cardata?.city || "",
      state: cardata?.state || "",
      web: cardata?.web || "",
      mail: cardata?.mail || "",
      twitter: cardata?.twitter || "",
      phone: cardata?.phone || "",
      facebook: cardata?.facebook || "",
      instagram: cardata?.instagram || "",
      mon: cardata?.mon || "",
      tues: cardata?.tues || "",
      wed: cardata?.wed || "",
      thrus: cardata?.thrus || "",
      fri: cardata?.fri || "",
      sat: cardata?.sat || "",
      sun: cardata?.sun || "",
      topoffer:cardata?.topoffer || "",
      carbanner:cardata?.carbanner || "",
      carbanner2:cardata?.carbanner2 || "",
      carbanner3:cardata?.carbanner3 || "",
      offer:cardata?.offer || "",
      finance:cardata?.finance || "",
      emailtoast:cardata?.emailtoast || "",
    },
    undefined
  );
  const {
    zip,
    city,
    name,
    street,
    state,
    web,
    mail,
    twitter,
    finance,
    phone,
    facebook,
    instagram,
    mon,
    tues,
    wed,
    thrus,
    fri,
    sat,
    sun,
    offer,
    topoffer,
    carbanner,
    carbanner2,
    carbanner3,
    emailtoast
  } = formState;
  const apidispatch = useDispatch();
  const handleInput = (e) => {
    dispatch({
      field: Object.keys(e.target.dataset)[0],
      value: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    var data = JSON.parse(JSON.stringify(formState));
    data.year = year;
    data.wheeldrive = wheeldrive;
    data.make = make;
    data.cartype = cartype;
    data.trim = trim;
    if (data.name) {
      apidispatch(upsertwebdetails(data))
        .then((values) => {
          let result = values?.payload?.data?.result;
          showToast({
            children: "Details Saved",
          });
        })
        .catch((error) => {});
    } else {
      showToast({
        children: "Please Enter name and other Details",
      });
    }
  };

  return (
    <>
      <Button onClick={toggleEditMode}>
        {editMode ? "Display-Only Mode" : "Edit Mode"}
      </Button>
      <Button onClick={handleSubmit}>Submit</Button>

      <Form>
        <FormGroup titleText="Address">
          <FormItem label="Name">
            <StandardField
              editMode={editMode}
              value={name}
              onInput={handleInput}
              data-name
            />
          </FormItem>
          <FormItem label="Street">
            <StandardField
              editMode={editMode}
              value={street}
              onInput={handleInput}
              data-street
            />
          </FormItem>
          <FormItem label="ZIP Code / City">
            {editMode ? (
              <>
                <Input
                  value={zip}
                  type={InputType.Number}
                  style={{ width: "30%" }}
                  onInput={handleInput}
                  data-zip
                />
                <Input
                  value={city}
                  style={{ width: "70%" }}
                  onInput={handleInput}
                  data-city
                />
              </>
            ) : (
              <Text>{`${zip} ${city}`}</Text>
            )}
          </FormItem>
          <FormItem label="State">
            <StandardField
              editMode={editMode}
              value={state}
              onInput={handleInput}
              data-state
            />
          </FormItem>
          <FormItem label="Web">
            <StandardField
              editMode={editMode}
              value={web}
              inputType={InputType.URL}
              onInput={handleInput}
              data-web
            />
          </FormItem>
        </FormGroup>
        <FormGroup titleText="Contact">
          <FormItem label="Email">
            <StandardField
              editMode={editMode}
              value={mail}
              inputType={InputType.Email}
              onInput={handleInput}
              data-email
            />
          </FormItem>
          <FormItem label="Twitter">
            <StandardField
              editMode={editMode}
              value={twitter}
              onInput={handleInput}
              data-twitter
            />
          </FormItem>
          <FormItem label="Finance">
            <StandardField
              editMode={editMode}
              value={finance}
              onInput={handleInput}
              data-finance
            />
          </FormItem>
          <FormItem label="Facebook">
            <StandardField
              editMode={editMode}
              value={facebook}
              onInput={handleInput}
              data-facebook
            />
          </FormItem>
          <FormItem label="Instagram">
            <StandardField
              editMode={editMode}
              value={instagram}
              onInput={handleInput}
              data-instagram
            />
          </FormItem>
          <FormItem label="Phone">
            <StandardField
              editMode={editMode}
              value={phone}
              inputType={InputType.Tel}
              onInput={handleInput}
              data-phone
            />
          </FormItem>
        </FormGroup>
        <FormGroup titleText="Timings">
          <FormItem label="Mon">
            <StandardField
              editMode={editMode}
              value={mon}
              onInput={handleInput}
              data-mon
            />
          </FormItem>
          <FormItem label="Tues">
            <StandardField
              editMode={editMode}
              value={tues}
              onInput={handleInput}
              data-tues
            />
          </FormItem>
          <FormItem label="Wed">
            <StandardField
              editMode={editMode}
              value={wed}
              onInput={handleInput}
              data-wed
            />
          </FormItem>
          <FormItem label="Thrus">
            <StandardField
              editMode={editMode}
              value={thrus}
              onInput={handleInput}
              data-thrus
            />
          </FormItem>
          <FormItem label="Fri">
            <StandardField
              editMode={editMode}
              value={fri}
              onInput={handleInput}
              data-fri
            />
          </FormItem>
          <FormItem label="Sat">
            <StandardField
              editMode={editMode}
              value={sat}
              onInput={handleInput}
              data-sat
            />
          </FormItem>
          <FormItem label="Sun">
            <StandardField
              editMode={editMode}
              value={sun}
              onInput={handleInput}
              data-sun
            />
          </FormItem>
        </FormGroup>
        <FormGroup titleText="Offers">
        <FormItem label="Top Offer">
            <StandardField
              editMode={editMode}
              value={topoffer}
              onInput={handleInput}
              data-topoffer
            />
          </FormItem>
          <FormItem label="Car Banner">
            <StandardField
              editMode={editMode}
              value={carbanner}
              onInput={handleInput}
              data-carbanner
            />
          </FormItem>
          <FormItem label="Car Banner 2">
            <StandardField
              editMode={editMode}
              value={carbanner2}
              onInput={handleInput}
              data-carbanner2
            />
          </FormItem>
          <FormItem label="Car Banner 3">
            <StandardField
              editMode={editMode}
              value={carbanner3}
              onInput={handleInput}
              data-carbanner3
            />
          </FormItem>
          <FormItem label="Email toast">
            <StandardField
              editMode={editMode}
              value={emailtoast}
              onInput={handleInput}
              data-emailtoast
            />
          </FormItem>
          <FormItem label="Offers">
            <StandardField
              editMode={editMode}
              value={offer}
              onInput={handleInput}
              data-offer
            />
          </FormItem>
    
        </FormGroup>
        <FormItem label="Make">
          <TextArea
            value={make.map((r) => r).join(",")}
            onChange={(e) => setmake(e.target.value.split(","))}
          />
        </FormItem>
        <FormItem>
          <List headerText="Make">
            {make.length !== 0 &&
              make.map((item, index) => (
                <StandardListItem additionalText={index + 1} key={index}>
                  {item}{" "}
                  <Icon
                    interactive
                    name="delete"
                    onClick={() => {
                      handlemakeRemove(index);
                    }}
                  >
                    Remove
                  </Icon>
                </StandardListItem>
              ))}
          </List>
        </FormItem>

        <FormItem label="Year">
          <TextArea
            value={year.map((r) => r).join(",")}
            onChange={(e) => setyear(e.target.value.split(","))}
          />
        </FormItem>
        <FormItem>
          <List headerText="Year">
            {year.length !== 0 &&
              year.map((item, index) => (
                <StandardListItem additionalText={index + 1} key={index}>
                  {item}{" "}
                  <Icon
                    interactive
                    name="delete"
                    onClick={() => {
                      handleyearRemove(index);
                    }}
                  >
                    Remove
                  </Icon>
                </StandardListItem>
              ))}
          </List>
        </FormItem>

        <FormItem label="wheeldrive">
          <TextArea
            value={wheeldrive.map((r) => r).join(",")}
            onChange={(e) => setwheeldrive(e.target.value.split(","))}
          />
        </FormItem>
        <FormItem>
          <List headerText="wheeldrive">
            {wheeldrive.length !== 0 &&
              wheeldrive.map((item, index) => (
                <StandardListItem additionalText={index + 1} key={index}>
                  {item}{" "}
                  <Icon
                    interactive
                    name="delete"
                    onClick={() => {
                      handlewheeldriveRemove(index);
                    }}
                  >
                    Remove
                  </Icon>
                </StandardListItem>
              ))}
          </List>
        </FormItem>
        <FormItem label="Trim">
          <TextArea
            value={trim.map((r) => r).join(",")}
            onChange={(e) => settrim(e.target.value.split(","))}
          />
        </FormItem>
        <FormItem>
          <List headerText="Trim">
            {trim.length !== 0 &&
              trim.map((item, index) => (
                <StandardListItem additionalText={index + 1} key={index}>
                  {item}{" "}
                  <Icon
                    interactive
                    name="delete"
                    onClick={() => {
                      handletrimRemove(index);
                    }}
                  >
                    Remove
                  </Icon>
                </StandardListItem>
              ))}
          </List>
        </FormItem>

        <FormItem label="Car Type">
          <TextArea
            value={cartype.map((r) => r).join(",")}
            onChange={(e) => setcartype(e.target.value.split(","))}
          />
        </FormItem>
        <FormItem>
          <List headerText="Car Type">
            {cartype.length !== 0 &&
              cartype.map((item, index) => (
                <StandardListItem additionalText={index + 1} key={index}>
                  {item}{" "}
                  <Icon
                    interactive
                    name="delete"
                    onClick={() => {
                      handlecartypeRemove(index);
                    }}
                  >
                    Remove
                  </Icon>
                </StandardListItem>
              ))}
          </List>
        </FormItem>
        <FormItem>
          <Button onClick={toggleEditMode}>
            {editMode ? "Display-Only Mode" : "Edit Mode"}
          </Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </FormItem>
      </Form>
    </>
  );
};

export default Contact;
