import React, { useEffect, useState, useRef, useReducer } from "react";
import "@ui5/webcomponents-icons/dist/AllIcons.js";
import _ from "lodash";
import Appshell from "../Components/AppShell";
import { useLocation } from "react-router-dom";
import Contact from "./Contact";
import Cars from "./Cars";
import CarDetail from "./CarDetail";
import { LineChart } from "@ui5/webcomponents-react-charts";
import {
  FlexBox,
  AnalyticalCard,
  AnalyticalCardHeader,
  NumericSideIndicator,
  DatePicker,
  MultiComboBox,
  MultiComboBoxItem,
  FormItem,
  FormGroup,
  ObjectPageSection,
  ObjectPage,
  Form,
  DynamicPage,
  DynamicPageHeader,
  Label,
  Link,
  Input,
  ObjectPageSubSection,
  Option,
  DynamicPageTitle,
  AnalyticalTableVisibleRowCountMode,
  ShellBar,
  AnalyticalTable,
  ObjectStatus,
  MessageStrip,
  Badge,
  Text,
  BreadcrumbsItem,
  Title,
  Breadcrumbs,
  Button,
  TextArea,
  Page,
  Bar,
} from "@ui5/webcomponents-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLocalStorageHelper } from "../helpers/localstorage";
export function Main() {
  const [layout, setlayout] = useState("cars");
  const ChangeColumnLayout = (value) => {
    setlayout(value);
  };
  const navigate = useNavigate();

  const { token } = useSelector((state) => ({
    ...state.carsSlice,
  }));

  var user = getLocalStorageHelper("user");

  if (!user) {
    navigate("/login");
  }

  return (
    <>
      <Appshell></Appshell>
      <ObjectPage
        headerContent={
          <DynamicPageHeader>
            <FlexBox alignItems="Center" wrap="Wrap">
              <FlexBox direction="Column">
                <Link>(+1) 414-737-7888</Link>
                <Link href="mailto:info@carget.us">info@carget.us</Link>
              </FlexBox>
              <FlexBox direction="Column" style={{ padding: "10px" }}>
                <Label>Sam</Label>
                <Label>WI, USA</Label>
              </FlexBox>
            </FlexBox>
          </DynamicPageHeader>
        }
        headerContentPinnable
        image="https://sap.github.io/ui5-webcomponents-react/assets/Person-e8B6nScF.png"
        imageShapeCircle
        mode="IconTabBar"
        onPinnedStateChange={function _a() {}}
        onSelectedSectionChange={function _a() {}}
        onToggleHeaderContent={function _a() {}}
        selectedSectionId="Contact"
        showHideHeaderButton
      >
        <ObjectPageSection aria-label="Cars" id="Cars" titleText="Cars">
          {layout === "cars" ? (
            <Cars ChangeColumnLayout={ChangeColumnLayout}></Cars>
          ) : (
            <CarDetail ChangeColumnLayout={ChangeColumnLayout}></CarDetail>
          )}
        </ObjectPageSection>
        <ObjectPageSection
          aria-label="Contact"
          id="Contact"
          titleText="Contact"
        >
          <Contact ChangeColumnLayout={ChangeColumnLayout}></Contact>
        </ObjectPageSection>
      </ObjectPage>
    </>
  );
}
