"use client";
import {
  AnalyticalTable,
  FlexBox,
  Button,
  CheckBox,
  Input,
  Icon,
  Toolbar,
  Text,
  Modals,
  ToolbarSpacer,
  FileUploader,
  Bar,
  BusyIndicator
} from "@ui5/webcomponents-react";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import React, { useState, useEffect, useMemo, useReducer } from "react";
import "@ui5/webcomponents-icons/dist/AllIcons.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getCars,
  setCurrentCar,
  upsertCars,
  deletecar,
  s3deletefolderfiles,
} from "../services/carsSlice";
import _ from "lodash";
import moment from "moment";
import * as xlsx from "xlsx";
const Cars = (props) => {
  const [cardata, setcardata] = useState([]);
  const [loading, setloading] = useState(false);
  const [search, setsearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    getCarsList();
  }, []);
  const showToast = Modals.useShowToast();
  const getCarsList = () => {
    dispatch(getCars())
      .then((values) => {
        let result = values?.payload?.data?.result;
        let sorted_array = _.orderBy(result, ["carstatus","updatedAt"], ["desc","desc"]);
        setcardata(sorted_array);
      })
      .catch((error) => {});
  };

  const handleSold = (e, row) => {
    var data = {};
    data.vin = row.original.vin;
    data.carstatus = e.target?.checked ? "Sold" : "Availble";

    if (data.vin) {
      dispatch(upsertCars(data))
        .then((values) => {
          let result = values?.payload?.data?.result;
          showToast({
            children: "Car Updated",
          });
          getCarsList();
        })
        .catch((error) => {});
    } else {
      showToast({
        children: "Please Enter Vin and other Details",
      });
    }
  };

  const handleExcel = async (data) => {
    var calls = [];

    for (let obj in data) {
      data[obj].vin = data[obj].vin.toString();
      data[obj].proddesc = data[obj].proddesc.split(",");
      data[obj].features = data[obj].features.split(",");
      calls.push(dispatch(upsertCars(data[obj])));
    }

    Promise.all(calls)
      .then((values) => {
        showToast({
          children: "Cars data Uploded",
        });
        getCarsList();
      })
      .catch((error) => {});
  };

  const showDialog = Modals.useShowDialog();
  const handleGridClick = (event, row, type) => {
    if (type === "edit") {
      dispatch(setCurrentCar(row.original))
        .then((values) => {
          props.ChangeColumnLayout("details");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {

      const { close } = showDialog({
        headerText: "Confirm",
        children:
          "Are you sure you want to delete Car This will delete all data?",
        footer: (
          <Bar
            endContent={
              <div>
                <Button
                  onClick={() => {
                    var data = {};
                    data.vin = row.original.vin;
              
                    if (data.vin) {
                      dispatch(s3deletefolderfiles(data))
                        .then((values) => {})
                        .catch((error) => {});
                      dispatch(deletecar(data))
                        .then((values) => {
                          let result = values?.payload?.data?.result;
                          showToast({
                            children: "Car Deleted",
                          });
                          getCarsList();
                        })
                        .catch((error) => {});
                    } else {
                      showToast({
                        children: "Please Enter Vin and other Details",
                      });
                    }
                    close();
                  }}
                >
                  Yes
                </Button>
                &nbsp;&nbsp;
                <Button onClick={() => close()}>No</Button>
              </div>
            }
          ></Bar>
        ),
      });


   
    }
  };

  const saveFile = async (e) => {
  
    if (e.detail.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        console.log(json);
        handleExcel(json);
        e.target.value = null
      };
      reader.readAsArrayBuffer(e.detail.files[0]);
    }
  };

  const toolbar = useMemo(() => (
    <>
      <Toolbar
        design="Auto"
        onClick={function _a() {}}
        onOverflowChange={function _a() {}}
        toolbarStyle="Standard"
      >
        <Input
          onChange={(e) => setsearch(e.target.value)}
          icon={<Icon interactive name="search" />}
          showClearIcon
        />

        <Button
          onClick={() => {
            dispatch(setCurrentCar({}))
              .then((values) => {
                props.ChangeColumnLayout("details");
              })
              .catch((error) => {
                console.log(error);
              });
          }}
        >
          Add Car
        </Button>
        <ToolbarSpacer />
        <FileUploader hideInput onChange={saveFile} accept=".xlsx,.xls">
          <Button>Upload Excel</Button>
        </FileUploader>
      </Toolbar>
    </>
  ));

  return (
    <>
  
      <AnalyticalTable
        header={toolbar}
        columns={[
          {
            Cell: (instance) => {
              return (
                <FlexBox>
                  <Button
                    icon="edit"
                    onClick={(e) => {
                      handleGridClick(
                        e,
                        instance.row,

                        "edit"
                      );
                    }}
                  />
                  <Button
                    icon="delete"
                    onClick={(e) => {
                      handleGridClick(
                        e,
                        instance.row,

                        "delete"
                      );
                    }}
                  />
                </FlexBox>
              );
            },
            Header: "Actions",
            accessor: ".",
            disableFilters: true,
            disableGroupBy: true,
            disableResizing: true,
            disableSortBy: true,
            id: "actions",
            minWidth: 150,
            width: 150,
          },
          {
            Header: "Sold",
            accessor: ".",
            Cell: (instance) => {
              return (
                <FlexBox>
                  <CheckBox
                    checked={instance.row.original.carstatus === "Sold"}
                    onChange={(e) => {
                      handleSold(e, instance.row);
                    }}
                  />
                </FlexBox>
              );
            },
          },
          {
            Header: "Vin",
            accessor: "vin",
          },
          {
            Header: "Model",
            accessor: "model",
          },
          {
            Header: "Make",
            accessor: "make",
          },
          {
            Header: "Trim",
            accessor: "trim",
          },
          {
            Header: "Engine",
            accessor: "engine",
          },
          {
            Header: "Year",
            accessor: "year",
          },
          {
            Header: "Miles",
            accessor: "miles",
          },
          {
            Header: "Price",
            accessor: "price",
          },

          {
            Header: "Owners",
            accessor: "numberofowners",
          },
          {
            Header: "WheelDrive",
            accessor: "wheeldrive",
          },
          {
            Header: "Transmission",
            accessor: "transmission",
          },
          {
            Header: "Interior",
            accessor: "interior",
          },
        
          {
            Header: "Exterior",
            accessor: "exterior",
          },
          {
            Header: "StockNo",
            accessor: "stockno",
          },
          {
            Header: "UpdatedAt",
            accessor: "updatedAt",
            Cell: (instance) => {
              return (
                <Text style={{ fontWeight: "bold", color: "green" }}>
                  {moment(instance.row.original["updatedAt"]).format(
                    "MM/DD/YYYY"
                  )}
                </Text>
              );
            },
          },
        ]}
        data={cardata && cardata}
        filterable
        groupable
        infiniteScroll
        globalFilterValue={search}
      />
    </>
  );
};

export default Cars;
