import { useState } from 'react';
import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Input,
  Label,
  Link,
  LinkDesign,
  ShellBar
} from '@ui5/webcomponents-react';
import './App.css';
import {
  BrowserRouter as Router, Route, Routes,
  useLocation,
  Navigate
} from 'react-router-dom';
import { Login } from './Components/Login';
import { Contact } from './Pages/Contact';
import { Main } from './Pages/Main';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function App() {

  return (
    <>
    
         <Router>
    <Routes>
      <Route exact path='/' element={<Main />} />
      <Route exact path='/main' element={<Main />} />
      <Route exact path='/login' element={<Login />} />
    </Routes>
  </Router>
    </>
  );
}

export default App;
