
import axios from 'axios'
import { saveLocalStorageHelper, getLocalStorageHelper, updateTokenIntoLocalStorageHelper } from "../../helpers/localstorage";
import { App } from './config';
import React, { useSelector} from 'react';
import {
    API_URL,
} from './config';
var isrefreshedToken = false;
const buildRequest = (request) => {
    
    isrefreshedToken = false;
    const { body, method, url, headers, bodyparams, responseType } = request;
    const contentType = body instanceof FormData
        ? 'multipart/form-data'
        : 'application/json';
    let headersObj = {
        'Content-Type': contentType,
    };

    var user = getLocalStorageHelper('user');

    if (user && 'token' in user) {
        headersObj["authorization"] = user.token;


    }

    const apiUrl = API_URL();
    var requestConfig = {
        baseURL: apiUrl,
        data: body,
        headers: headersObj,
        method,
        url
    };

    if (responseType) {
        requestConfig["responseType"] = responseType;
    }


    if (bodyparams) {
        requestConfig["params"] = bodyparams
    }

    return requestConfig;
};
export const makeRequest = async (request) => {

    const requestConfig = buildRequest(request);
    return new Promise((resolve, reject) => {
        const axiosRequest = axios(requestConfig);
        axiosRequest
            .then((values) => {

                if (values.headers && "salvo-auth" in values.headers) {
                    updateTokenIntoLocalStorageHelper('user', values.headers["salvo-auth"]);
                }

                resolve(values);
            })
            .catch(async (error) => {

                //console.log("here : " + error);
                //console.log("isrefreshedToken " + isrefreshedToken)
                if (error.response && error.response.status === 401 && !isrefreshedToken) {

                  
                } else {
                    //console.log("error else : " + error)
                    if (error.response && error.response.status === 401 && !isrefreshedToken) {
                        var data = getLocalStorageHelper("user");
                        window.location.href = window.location.origin + App.loginRouters.logoutUrlParams + `${encodeURIComponent(window.location.origin)}` + "&token=" + `${encodeURIComponent(data.accessToken)}`;
                        localStorage.clear();
                    } else {
                        var errors = await formatError(error)
                        reject(errors);
                    }


                }










            });


    });
};

export const defaultResponse = {
    status: 500,
    data: {
        error: 'Server error',
    },
};

export const formatError = async (responseError) => {
    var blobError = "Server Error";
    try {
        var blobErrorData = JSON.parse(await responseError.response.data.text()).message;
        if (blobErrorData && blobErrorData.length > 0) {
            blobError = blobErrorData
        }
    } catch (err) { }
    const response = responseError.response || defaultResponse;
    const errors = response.data && (response.data.errors || response.data.error || response.data.message);
    return {
        code: response.status,
        message: errors || blobError,
    };
};


