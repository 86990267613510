import "@ui5/webcomponents-icons/dist/AllIcons.js";
import {
  List,
  StandardListItem,
  Card,
  Label,
  FormBackgroundDesign,
  Form,
  ComboBox,
  Text,
  BusyIndicator,
  TextArea,
  Carousel,
  ComboBoxItem,
  Modals,
  CustomListItem,
  FlexBox,
  FormItem,
  Input,
  FormGroup,
  Icon,
  Toast,
  Button,
  InputType,
  Toolbar,
  ToolbarSpacer,
  Title,
} from "@ui5/webcomponents-react";
import React, { useState, useEffect, useRef, useReducer } from "react";
import Sortable from "sortablejs";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import Resizer from "react-image-file-resizer";
import ImageUploading from "react-images-uploading";
import { spacing } from "@ui5/webcomponents-react-base";
import { useDispatch, useSelector } from "react-redux";
import heic2any from "heic2any";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import {
  upsertCars,
  uploads3file,
  s3getfolderfiles,
  uploads3base64file,
  s3deletefile,
  setCurrentCar,
  s3deletefolderfiles,
} from "../services/carsSlice";
import _ from "lodash";
import { current } from "@reduxjs/toolkit";
import { ReactSortable } from "react-sortablejs";

export default function CarDetail(props) {
  const gridRef = useRef(null);
  const sortableJsRef = useRef(null);
  const initData = Array.from({ length: 100 }, (_, i) => ({
    _id: (i + 1).toString(),
    content: (i + 1).toString(),
  }));

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const toast = useRef(null);
  const carouselRef = useRef(null);
  const { currentcar, cargetdetails } = useSelector((state) => ({
    ...state.carsSlice,
  }));
  const showToast = Modals.useShowToast();
  // const [features, setfeatures] = useState(currentcar?.features || []);
  // const [proddesc, setproddesc] = useState(currentcar?.proddesc || []);
  const [carimagesdata, setcarimagesdata] = useState([]);
  const apidispatch = useDispatch();
  const [images, setImages] = React.useState([]);
  const [loading, setloading] = React.useState(false);
  const [data, setData] = useState(initData);
  const onListChange = () => {
    const newData = [...gridRef.current.children]
      .map((i) => i.dataset.id)
      .map((id) => data.find((item) => item._id === id));

    setData(data);
  };

  useEffect(() => {
    if (images.length !== 0) {
      sortableJsRef.current = new Sortable(gridRef.current, {
        animation: 150,
        onEnd: onListChange,
      });
    }
  }, [images]);
  const maxNumber = 50;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  useEffect(() => {
    getCarsList();
  }, [currentcar]);

  const getCarsList = async () => {
    apidispatch(s3getfolderfiles({ vin: currentcar.vin }))
      .then(async (values) => {
        let result = values?.payload?.data?.result;

        result.forEach((item, index) => {
          item.filename = item.Key.split("/")[2];
          item.fileorder = parseInt(
            item.Key.split("/")[2].replace(".jpeg", "")
          );
        });

        let sorted_array = _.orderBy(result, ["fileorder"], ["asc"]);

        setcarimagesdata(sorted_array);

        if (!currentcar?.profileimg === "") {
          handleImage();
        }
      })
      .catch((error) => {});
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1280,
        720,
        "jpeg",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const reducer = (state, { field, value }) => {
    return { ...state, [field]: value };
  };
  const [formState, dispatch] = useReducer(
    reducer,
    {
      year: currentcar?.year || "",
      make: currentcar?.make || "",
      model: currentcar?.model || "",
      trim: currentcar?.trim || "",
      engine: currentcar?.engine || "",
      wheeldrive: currentcar?.wheeldrive || "",
      transmission: currentcar?.transmission || "",
      exterior: currentcar?.exterior || "",
      interior: currentcar?.interior || "",
      stockno: currentcar?.stockno || "",
      miles: currentcar?.miles || "",
      cartype: currentcar?.cartype || "",
      numberofowners: currentcar?.numberofowners,
      price: currentcar?.price || "",
      description: currentcar?.description || "",
      vin: currentcar?.vin || "",
      proddesc: currentcar?.proddesc || "",
      features: currentcar?.features || "",
      message: "",
    },
    undefined
  );
  const {
    year,
    make,
    model,
    trim,
    engine,
    wheeldrive,
    miles,
    cartype,
    description,
    numberofowners,
    price,
    message,
    vin,
    features,
    proddesc,
    transmission,
    exterior,
    interior,
    stockno
  } = formState;

  const handleInput = (e) => {
    dispatch({
      field: Object.keys(e.target.dataset)[0],
      value: e.target.value,
    });
  };

  const handleRemoveFeature = (id) => {
    let copy = [...features];
    copy.splice(id, 1);
    // setfeatures(copy);
  };

  const handleRemoveProd = (id) => {
    let copy = [...proddesc];
    copy.splice(id, 1);
    // setproddesc(copy);
  };

  const handleSubmit = (e) => {
    var data = JSON.parse(JSON.stringify(formState));
    delete data.message;
    // data.features = features;
    // data.proddesc = proddesc;
    if (data.vin) {
      apidispatch(upsertCars(data))
        .then((values) => {
          let result = values?.payload?.data?.result;
          showToast({
            children: "Car Saved",
          });
        })
        .catch((error) => {});
    } else {
      showToast({
        children: "Please Enter Vin and other Details",
      });
    }
  };

  const saveFile = async (e) => {
    setOpen(open);
    var calls = [];
    var index = 0;
    for (let obj in images) {
      index++;
      let file;
      let fileheic;
      // file conversion takes a bit.
      try {
        fileheic = await heic2any({
          blob: images[obj].file,
          toType: "image/jpeg",
          quality: 0.5, // cuts the quality and size by half
        });
      } catch {}

      if (fileheic) {
        file = await resizeFile(fileheic);
      } else {
        file = await resizeFile(images[obj].file);
      }

      if (file) {
        let formData = {}; //formdata object

        formData["file"] = file; //append the values with key, value pair
        formData["vin"] = vin;
        formData.filename = index + ".jpeg"; //images[obj].file.name;
        await apidispatch(uploads3base64file(formData));
        //calls.push(apidispatch(uploads3base64file(formData)));
      }
    }

    getCarsList();
    setImages([]);
    showToast({
      children: "Images Uploded",
    });
    setOpen(false);
    // Promise.all(calls)
    //   .then((values) => {
    //     showToast({
    //       children: "Images Uploded",
    //     });
    //     getCarsList();
    //     setImages([]);
    //   })
    //   .catch((error) => {
    //     debugger;
    //     showToast({
    //       children: "Images Uploded Issue",
    //     });
    //   });
  };

  const handleImage = (type, indexparam) => {
    setOpen(true);
    var index = indexparam;

    if (type === "delete") {
      apidispatch(s3deletefile({ key: carimagesdata[index].Key }))
        .then((values) => {
          showToast({
            children: "Image Deleted",
          });

          if (
            carimagesdata[index].Key ===
            carimagesdata[
              _.findIndex(carimagesdata, (e) => {
                return e.Key === currentcar?.profileimg;
              })
            ]?.s3url
          ) {
            var data = {};
            data.vin = currentcar.vin;
            data.profileimg = "";
            apidispatch(upsertCars(data)).then((values) => {
              var car = _.clone(currentcar);
              car.profileimg = "";
              showToast({
                children: "Profile Image Updated",
              });
              apidispatch(setCurrentCar(car))
                .then((values) => {})
                .catch((error) => {
                  console.log(error);
                });
            });
          }
          getCarsList();
          setOpen(false);
        })
        .catch((error) => {
          setOpen(false);
        });
    } else {
      if (carimagesdata.length !== 0) {
        var data = {};
        data.vin = currentcar.vin;
        data.profileimg = carimagesdata[index].Key;
        apidispatch(upsertCars(data)).then((values) => {
          var car = _.clone(currentcar);
          car.profileimg = carimagesdata[index].Key;
          showToast({
            children: "Profile Image Updated",
          });
          apidispatch(setCurrentCar(car))
            .then((values) => {})
            .catch((error) => {
              console.log(error);
              setOpen(false);
            });
        });
      }
    }
    setOpen(false);
  };

  const moveUp = (array, index) => {
    debugger;
    if (index < 1 || index >= array.length) {
      // Can't move the 0th item or any item outside the bounds!
      return;
    }

    [array[index - 1], array[index]] = [array[index], array[index - 1]];
    setImages(array);
  };

  const handleChangePosition = (
    array,
    element,
    direction = "up",
    itemindex
  ) => {
    debugger;
    // get the original index of element
    const index = itemindex;

    // update new Index depending on direction
    let newIndex = -1;
    if (direction === "up" && index !== 0) {
      newIndex = index - 1;
    } else if (direction === "down" && index !== array.length + 1) {
      newIndex = index + 1;
    }

    if (newIndex >= 0) {
      // filter the array to remove the element to move
      //const filteredArray=[array[index]];
      const filteredArray = _.pullAllWith(array, [element], _.isEqual); //array.RemoveAt(index);

      // based on the new index, insert the element at the new position
      const updatedArray = [
        // Elements before the insertion point:
        ...filteredArray.slice(0, newIndex),
        // The element to move
        element,
        // Elements after the insertion point:
        ...filteredArray.slice(newIndex),
      ];
      setImages(updatedArray);
    }
  };

  const handledeleteallimages = (type, indexparam) => {
    setOpen(true);
    var data = {};
    data.vin = currentcar.vin;
    apidispatch(s3deletefolderfiles(data))
      .then((values) => {
        showToast({
          children: "Images Deleted",
        });
        var data = {};
        data.vin = currentcar.vin;
        data.profileimg = "";
        setImages([]);
        apidispatch(upsertCars(data)).then((values) => {
          var car = _.clone(currentcar);
          car.profileimg = "";
          showToast({
            children: "Profile Image Updated",
          });
          apidispatch(setCurrentCar(car))
            .then((values) => {})
            .catch((error) => {
              console.log(error);
            });
        });
        setImages([]);
        getCarsList();
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toast ref={toast}>{message}</Toast>
      <Button onClick={handleSubmit}>Submit</Button>
      <Button onClick={() => props.ChangeColumnLayout("cars")}>Back</Button>

      <Form
        backgroundDesign={FormBackgroundDesign.Transparent}
        columnsL={1}
        columnsM={1}
        columnsS={1}
        columnsXL={2}
        labelSpanL={4}
        labelSpanM={2}
        labelSpanS={12}
        labelSpanXL={4}
        style={{
          alignItems: "center",
        }}
      >
        <FormGroup titleText="Car Details">
          <FormItem label={<Label required>VIN</Label>}>
            <Input
              required
              type={InputType.Text}
              value={vin}
              onInput={handleInput}
              data-vin
            />
          </FormItem>
          <FormItem label={<Label required>Year</Label>}>
            <ComboBox value={year} onChange={handleInput} data-year>
              {cargetdetails &&
                cargetdetails.year.length !== 0 &&
                cargetdetails.year.map((item, index) => (
                  <ComboBoxItem key={index} text={item} />
                ))}
            </ComboBox>
          </FormItem>

          <FormItem label={<Label required>Make</Label>}>
            <ComboBox value={make} onChange={handleInput} data-make>
              {cargetdetails &&
                cargetdetails.make.length !== 0 &&
                cargetdetails.make.map((item, index) => (
                  <ComboBoxItem key={index} text={item} />
                ))}
            </ComboBox>
          </FormItem>
          <FormItem label={<Label required>Model</Label>}>
            <Input
              required
              type={InputType.Text}
              value={model}
              onInput={handleInput}
              data-model
            />
          </FormItem>

          <FormItem label={<Label required>Trim</Label>}>
            <ComboBox value={wheeldrive} onChange={handleInput} data-trim>
              {cargetdetails &&
                cargetdetails.trim.length !== 0 &&
                cargetdetails.trim.map((item, index) => (
                  <ComboBoxItem key={index} text={item} />
                ))}
            </ComboBox>
          </FormItem>

          <FormItem label={<Label required>Engine size</Label>}>
            <Input
              required
              type={InputType.Text}
              value={engine}
              onInput={handleInput}
              data-engine
            />
          </FormItem>

          <FormItem label={<Label required>Transmission</Label>}>
            <Input
              required
              type={InputType.Text}
              value={transmission}
              onInput={handleInput}
              data-transmission
            />
          </FormItem>

          <FormItem label={<Label required>Exterior</Label>}>
            <Input
              required
              type={InputType.Text}
              value={exterior}
              onInput={handleInput}
              data-exterior
            />
          </FormItem>

          <FormItem label={<Label required>Interior</Label>}>
            <Input
              required
              type={InputType.Text}
              value={interior}
              onInput={handleInput}
              data-interior
            />
          </FormItem>

          <FormItem label={<Label required>Stock No</Label>}>
            <Input
              required
              type={InputType.Text}
              value={stockno}
              onInput={handleInput}
              data-stockno
            />
          </FormItem>

          <FormItem label={<Label required>Wheel Drive</Label>}>
            <ComboBox value={wheeldrive} onChange={handleInput} data-wheeldrive>
              {cargetdetails &&
                cargetdetails.wheeldrive.length !== 0 &&
                cargetdetails.wheeldrive.map((item, index) => (
                  <ComboBoxItem key={index} text={item} />
                ))}
            </ComboBox>
          </FormItem>
          <FormItem label={<Label required>Miles</Label>}>
            <Input
              required
              type={InputType.Text}
              value={miles}
              onInput={handleInput}
              data-miles
            />
          </FormItem>
          <FormItem label={<Label required>Car Type</Label>}>
            <ComboBox value={cartype} onChange={handleInput} data-cartype>
              {cargetdetails &&
                cargetdetails.cartype.length !== 0 &&
                cargetdetails.cartype.map((item, index) => (
                  <ComboBoxItem key={index} text={item} />
                ))}
            </ComboBox>
          </FormItem>
          <FormItem label={<Label required>Number Of Owners</Label>}>
            <Input
              required
              type={InputType.Text}
              value={numberofowners}
              onInput={handleInput}
              data-numberofowners
            />
          </FormItem>
          <FormItem label={<Label required>Price</Label>}>
            <Input
              required
              type={InputType.Text}
              value={price}
              onInput={handleInput}
              data-price
            />
          </FormItem>
          {carimagesdata.length !== 0 && (
            <FormItem>
              <Button onClick={() => handledeleteallimages()}>
                Delete All Images
              </Button>
            </FormItem>
          )}
          <FormItem label={<Label required>Car Images</Label>}>
            <div ref={gridRef}>
              {carimagesdata.length !== 0 &&
                carimagesdata.map((item, index) => (
                  <div key={index} data-id={index} className="grid-square">
                    <img
                      width="100"
                      height="100"
                      alt="img-sample 1"
                      key={index}
                      src={item.s3url}
                    />
                    {item.s3url !==
                      carimagesdata[
                        _.findIndex(carimagesdata, (e) => {
                          return e.Key === currentcar?.profileimg;
                        })
                      ]?.s3url && (
                      <Icon
                        onClick={() => handleImage("profile", index)}
                        interactive
                        name="heart-2"
                      />
                    )}

                    <Icon
                      onClick={() => handleImage("delete", index)}
                      interactive
                      name="delete"
                    />
                  </div>
                ))}
            </div>
          </FormItem>
          <FormItem>
            <ImageUploading
              allowNonImageType
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
              acceptType={["jpg", "png", "jpeg", "HEIC"]}
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <>
                  <div className="upload__image-wrapper">
                    {currentcar?.vin && (
                      <>
                        <Button
                          style={isDragging ? { color: "red" } : null}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Add Car Images
                        </Button>
                        {images && images.length !== 0 && (
                          <Button onClick={saveFile}>Upload Car Images</Button>
                        )}
                        <ToolbarSpacer />
                        {images.length !== 0 && (
                          <Button onClick={onImageRemoveAll}>
                            Remove all Images
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                  {/* {images.length !== 0 && (
                    <FormItem>
                      <List
                        header={
                          <>
                            <Toolbar
                              design="Auto"
                              onClick={function _a() {}}
                              onOverflowChange={function _a() {}}
                              toolbarStyle="Standard"
                            >
                              <Title level="H4">Car Images</Title>
                              <ToolbarSpacer />
                              {images && images.length !== 0 && (
                                <Button onClick={saveFile}>
                                  Upload Car Images
                                </Button>
                              )}
                              <ToolbarSpacer />
                              {images.length !== 0 && (
                                <Button onClick={onImageRemoveAll}>
                                  Remove all Images
                                </Button>
                              )}
                            </Toolbar>
                          </>
                        }
                        onItemClick={function _a() {}}
                        onItemClose={function _a() {}}
                        onItemDelete={function _a() {}}
                        onItemToggle={function _a() {}}
                        onLoadMore={function _a() {}}
                        onSelectionChange={function _a() {}}
                      >
                        {imageList.map((image, index) => (
                          <CustomListItem key={index}>
                            <FlexBox
                              key={index}
                              justifyContent="SpaceAround"
                              style={{
                                width: "100%",
                              }}
                            >
                              <img
                                width="240"
                                height="160"
                                alt={image.file.name}
                                key={index}
                                src={image.data_url || image.file}
                              />

                              <Button
                                onClick={() =>
                                  handleChangePosition(
                                    imageList,
                                    image,
                                    "up",
                                    index
                                  )
                                }
                              >
                                Move Up
                              </Button>
                              <Button
                                onClick={() =>
                                  handleChangePosition(
                                    imageList,
                                    image,
                                    "down",
                                    index
                                  )
                                }
                              >
                                Move Down
                              </Button>

                              <Button onClick={() => onImageUpdate(index)}>
                                Update
                              </Button>

                              <Button onClick={() => onImageRemove(index)}>
                                Remove
                              </Button>
                            </FlexBox>
                          </CustomListItem>
                        ))}
                      </List>
                    </FormItem>
                  )} */}
                  <ReactSortable
                    list={imageList}
                    setList={(newState) => {
                      imageList = newState;
                      setImages(newState);
                      console.log(imageList, "imageList");
                      console.log(images, "images");
                    }}
                  >
                    {imageList.map((image, index) => (
                      <div key={index} data-id={index} className="grid-square">
                        <img
                          width="100"
                          height="100"
                          alt={image.file.name}
                          key={index}
                          src={image.data_url || image.file}
                        />
                        <Icon
                          onClick={() => onImageUpdate(index)}
                          interactive
                          name="add-photo"
                        />

                        <Icon
                          onClick={() => onImageRemove(index)}
                          interactive
                          name="delete"
                        />
                      </div>
                    ))}
                  </ReactSortable>
                </>
              )}
            </ImageUploading>
          </FormItem>

          {/* {carimagesdata.length !== 0 && (
            <FormItem label="Images">
              <Carousel
                ref={carouselRef}
                arrowsPlacement="Navigation"
                backgroundDesign="Transparent"
                pageIndicatorStyle="Numeric"
                cyclic
              >
                {carimagesdata.length !== 0 &&
                  carimagesdata.map((item, index) => (
                    <>
                      <img
                        width="640"
                        height="480"
                        alt="img-sample 1"
                        key={index}
                        src={item.s3url}
                      />
                    </>
                  ))}
              </Carousel>
            </FormItem>
          )}
          {carimagesdata.length !== 0 && (
            <FormItem>
              <Button onClick={() => handleImage("profile")}>
                Make Profile Picture
              </Button>
              <Button onClick={() => handleImage("delete")}>Delete</Button>
            </FormItem>
          )} */}

          {/* <FormItem label={<Label required>Car Main Descritpion</Label>}>
            <TextArea
              required
              type={InputType.Text}
              value={description}
              onInput={handleInput}
              data-description
            />
          </FormItem> */}

          <FormGroup titleText="Features">
            <FormItem label="Feature">
              <TextArea
                growing
                growingMaxLines={20}
                value={features}
                onInput={handleInput}
                data-features
                // onChange={(e) => setfeatures(e.target.value.split(","))}
              />
            </FormItem>

            {/* <FormItem>
              <List headerText="Features">
                {features.length !== 0 &&
                  features.map((item, index) => (
                    <StandardListItem additionalText={index + 1} key={index}>
                      {item}{" "}
                      <Icon
                        interactive
                        name="delete"
                        onClick={() => {
                          handleRemoveFeature(index);
                        }}
                      >
                        Remove
                      </Icon>
                    </StandardListItem>
                  ))}
              </List>
            </FormItem> */}
            <FormItem></FormItem>
          </FormGroup>
          <FormGroup titleText="Description">
            <FormItem label="Description">
              <TextArea
                growing
                growingMaxLines={20}
                value={proddesc}
                onInput={handleInput}
                data-proddesc
                // onChange={(e) => setproddesc(e.target.value.split(","))}
              />
            </FormItem>

            {/* <FormItem>
              <List headerText="Description">
                {proddesc.length !== 0 &&
                  proddesc.map((item, index) => (
                    <StandardListItem additionalText={index + 1} key={index}>
                      {item}{" "}
                      <Icon
                        interactive
                        name="delete"
                        onClick={() => {
                          handleRemoveProd(index);
                        }}
                      >
                        Remove
                      </Icon>
                    </StandardListItem>
                  ))}
              </List>
            </FormItem> */}
            <FormItem></FormItem>
          </FormGroup>
        </FormGroup>

        <FormItem>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={() => props.ChangeColumnLayout("cars")}>
            Cancel
          </Button>
        </FormItem>
      </Form>
    </>
  );
}
