'use client';
import {
  FormGroup,
  DynamicPage,
  Form,
  FormItem,
  Input,
  Menu ,
  Label,
  Button,
  Modals,
  InputType,
  BusyIndicator
  
} from '@ui5/webcomponents-react';
import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js';
import React, { useState, useEffect, useRef,useReducer } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { userlogin } from "../services/carsSlice";
import { useNavigate } from "react-router-dom";
import { saveLocalStorageHelper } from "../helpers/localstorage";
import Appshell from '../Components/AppShell'

export function Login() {
  const showToast = Modals.useShowToast();
  const [isregister, setisregister] = useState(false);
  const loading = false;
  const apidispatch = useDispatch();

  const [isotp, setisotp] = useState(false);
  const navigate = useNavigate();



  const reducer = (state, { field, value }) => {
    return { ...state, [field]: value };
  };
  const [formState, dispatch] = useReducer(
    reducer,
    {
      email: '',
      password: '',
      username: '',
      session: '',
      message: ''
    },
    undefined
  );
  const {
    email,
    password,
    username,
    session,
    message
  } = formState;





  const handleGetOTP = (e) => {
    e.preventDefault();

  



  };
  
  const handleUnRegister = () => {
    setisregister(true);
   
  };
  const verify = () => {

  };

  const handleInput = (e) => {
    
    dispatch({
      field: Object.keys(e.target.dataset)[0],
      value: e.target.value,
    });
  };



  const login = () => {
    apidispatch(userlogin({username:email,password:password}))
    .then((values) => {
     
      let result = values?.payload?.data?.result?.token;
      saveLocalStorageHelper('user', values?.payload?.data?.result );
      navigate("/main");
      
    })
    .catch((error) => {
      
      showToast({
        children: "Invalid Creds"
      });

    });

  };
  return (
    <>
     
      {loading ? (
        <BusyIndicator active />

      ) : (
        <>
          <Appshell></Appshell>
        <DynamicPage showHideHeaderButton={false} >

          <Form onSubmit={handleGetOTP}>

            <FormGroup titleText={isregister ? "Please Register" : "Login"}>
              <FormItem label={<Label required>Email</Label>}  >
                <Input required type={InputType.Text}  value={email} 
                 onInput={handleInput}
                 data-email />
              </FormItem>
             
                <FormItem label={<Label required>Password</Label>}  >
                  <Input required type={InputType.Password} value={password} 
                   onInput={handleInput}
                   data-password
                  
                 />
                </FormItem>
             



              <FormItem>
               
                  <Button
                    design="Emphasized"
                    onClick={()=>login()}
                  >
                    Login
                  </Button>
                
           
              </FormItem>


            </FormGroup>
       

          </Form>

        </DynamicPage >
        </>
      )}

    </>
  );
}
