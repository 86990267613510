'use client';
import React, { useState, useEffect, useRef,useReducer } from 'react';
import navBackIcon from '@ui5/webcomponents-icons/dist/nav-back.js';
import { Menu, ShellBar,MenuItem,Avatar } from '@ui5/webcomponents-react';
import '@ui5/webcomponents-react/dist/Assets.js';
import { getLocalStorageHelper,saveLocalStorageHelper } from "../helpers/localstorage";
import { useNavigate } from "react-router-dom";
const AppShell = (props) => {  
  var user = getLocalStorageHelper('user');
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const history = useNavigate();
  return (
    <>

      <ShellBar
       profile={user && <Avatar interactive  id={'openMenuBtn'}
       ><img src="https://sap.github.io/ui5-webcomponents-react/assets/Person-e8B6nScF.png" /></Avatar>}
       onProfileClick={() => {
        setMenuIsOpen(true);
      }}
        primaryTitle={'CarGet'}
        secondaryTitle={'Admin'}
      
      />
           <Menu
    opener={'openMenuBtn'}
    open={menuIsOpen}
    onAfterClose={() => {
      setMenuIsOpen(false);
    }}
    onItemClick={()=>{window.localStorage.clear(); history('/login')}}
  >

    <MenuItem
    interactive
      icon="journey-arrive"
      text="Logout"
      onClick={()=>{debugger;window.localStorage.clear();}}
    />
  </Menu>
    </>
  );
}

export default AppShell;